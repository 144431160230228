import { combineReducers } from 'redux';

import noticeList from '@/shared/redux/noticeList/reducers';

// TODO: Add reducers as we need them
import apollo from '../../redux/apollo/reducers';
import chat from '../../shared/redux/chat/reducers';
import shipments from '../../shared/redux/shipments/reducers';
import uiEvents from '../../shared/redux/uiEvents/reducers';
import user from '../../shared/redux/user/reducers';

// Root Reducer to pass into store
export default combineReducers({
  apollo,
  chat,
  shipments,
  noticeList,
  uiEvents,
  user,
});
