import { createTheme } from '@mui/material/styles';

const frontTheme = createTheme({
  // Using the mixed font of both Montserrat and Noto Sane JP
  // for corresponding glyphs
  typography: {
    fontFamily: [
      'Noto Sans JP',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Segoe UI',
      'Helvetica Neue',
      'Hiragino Kaku Gothic ProN',
      'Meiryo',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#000665',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#2AE61E',
      contrastText: '#FFF',
    },
    neutral: {
      main: '#FFF',
      contrastText: '#000665',
    },
    error: {
      main: '#D53612',
    },
  },
});

// see: https://mui.com/blog/mui-core-v5/#dynamic-props
declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export default frontTheme;
