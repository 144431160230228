import {
  ApolloLink, ApolloClient, ApolloProvider, InMemoryCache,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { persistCache } from 'apollo3-cache-persist';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { AuthInfo } from '@/shared/utils/auth/auth';

import {
  createErrorLink,
  createHeadersAuthLink,
  createMainLink,
} from './apolloLinks';
import indexedDBWrapper from './indexedDBWrapper';

// https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
// UPDATE: https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
// Cache-and-network behavior has changed for AC3. Therefore, to implement similar behavior
// we will make use of next fetch policy and set to cache-first as the default.
const FETCH_POLICY = 'cache-and-network';
const NEXT_FETCH_POLICY = 'cache-first';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const firefoxPrivateCheck = () => {
  const isFirefox = 'MozAppearance' in document.documentElement.style;
  if (!isFirefox) {
    return Promise.resolve(false);
  }
  // If no tab is open in FF, indexedDB is not available
  // This is theoretically impossible for our case,
  // But I'd like to leave this check in anyway.
  if (indexedDB === null) {
    return Promise.resolve(true);
  }
  return new Promise((resolve) => {
    const db = indexedDB.open('shpFFprivatCheck');
    db.onsuccess = () => {
      db.result.close();
      indexedDB.deleteDatabase('shpFFprivatCheck');
      resolve(false);
    };
    db.onerror = () => {
      indexedDB.deleteDatabase('shpFFprivatCheck');
      resolve(true);
    };
  });
};

const ApolloWrapper = ({ children }) => {
  // CAREFUL HERE: useAuth is not available, therefore fallback to useAuth0
  const { getIdTokenClaims, logout, user } = useAuth0();
  const [client, setClient] = useState();

  /**
   * If an auth token is present in cookies at the time of mount, this creates a client
   * whose subscription link is able to connect to AppSync.
   * If an auth token is **not** present on mount, because user is not yet logged in,
   * this creates an "unauthorized" client able to login, but unable to connect to AppSync
   * Once a token is available after login, this creates a new client that **is** able to
   * connect to AppSync.
   */
  useEffect(() => {
    (async () => {
      // Do we need to set any settings?
      const cache = new InMemoryCache({
        possibleTypes: {
          ContextTypeUnion: ['Shipment', 'QuoteLane'],
        },
        typePolicies: {
          // Warning where quote request details has no id, therefore we need to configure this
          // type to merge for caching to work properly
          // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#configuring-merge-functions-for-types-rather-than-fields
          QuoteRequestDetails: {
            merge: true,
          },
        },
      });

      if (client) {
        client.clearStore();
        client.stop();
      }

      const { isStaffUser } = new AuthInfo(user);
      // may be undefined but that's okay
      const token = user
        ? (await getIdTokenClaims())?.__raw
        : undefined;

      // Create the Apollo Client
      const apolloClient = new ApolloClient({
        // apply all given links AND concat network status link with http or batch link
        link: ApolloLink.from([
          createHeadersAuthLink(token, isStaffUser),
          createErrorLink(logout),
          createMainLink(token),
        ]),
        cache,
        // Automatically set all queries to constant FETCH_POLICY so we don't have to manually
        // set it for each query
        // ---> Context into why we set watchQuery, not query
        // React Apollo uses Apollo Client's watchQuery internally, not query. That means
        // the defaultOptions have to be set for watchQuery to be enabled.
        // https://github.com/apollographql/react-apollo/issues/3163
        // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.watchQuery
        defaultOptions: {
          watchQuery: {
            fetchPolicy: FETCH_POLICY,
            nextFetchPolicy: NEXT_FETCH_POLICY,
          },
        },
      });

      // Don't enable persist cache for staff, I'm not sure how we
      // can clear storage if the staff user logs out on BO...
      if (!token || isStaffUser()) {
        setClient(apolloClient);
      } else {
        // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.onResetStore
        // When we reset the store, make sure to delete all cached data from apollo-cache-persist
        apolloClient.onResetStore(() => {
          apolloClient.writeData({ data: {} });
        });
        // Our implementation of indexedDB made it necessary to check for Firefox + Private Browsing
        // This is necessary since FF Private does not allow indexedDB to be used.
        // In this case, we fall back on the localStorage.
        // This code has been adapted from: https://gist.github.com/jherax/a81c8c132d09cc354a0e2cb911841ff1
        firefoxPrivateCheck().then((isFirefoxPrivate) => (
          persistCache({
            cache,
            storage: isFirefoxPrivate ? window.localStorage : indexedDBWrapper,
            // Un-limit the max size, default ~ 1MB
            maxSize: false,
          })
        )).then(() => {
          setClient(apolloClient);
        }).catch((error) => {
          // What kind of error handling should we have here?
          // eslint-disable-next-line no-console
          console.error('Error restoring Apollo cache', error);
        });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (client === undefined) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

ApolloWrapper.propTypes = propTypes;

export default ApolloWrapper;
