import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { LoginUserRole } from '@/shared/utils/auth/typings';
import { STORAGE_ALLOW_UI_EVENTS } from '@/shared/utils/constants/auth';
import { CLIENT_ID, DOMAIN } from '@/shared/utils/constants/auth0';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState, user) => {
    history.push(appState?.returnTo || window.location.pathname);

    // necessary to tell redux-middleware to allow recording of UI events
    localStorage.setItem(
      STORAGE_ALLOW_UI_EVENTS,
      user.login_user_role === LoginUserRole.Staff ? 'enabled' : '',
    );
  };

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = propTypes;

export default Auth0ProviderWithHistory;
