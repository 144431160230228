import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { AuthInfo } from '@/shared/utils/auth/auth';
import { LOCAL_STORAGE_REMOVABLE_KEYS } from '@/shared/utils/constants/auth';

export const useAuth = () => {
  const apolloClient = useApolloClient();
  const auth0 = useAuth0();

  const {
    user, logout: logoutAuth0,
  } = auth0;

  const handleLogout = useCallback(() => {
    if (typeof localStorage !== 'undefined') {
      // TODO: better to use a whitelist, see https://shippio.atlassian.net/browse/TECH-288
      LOCAL_STORAGE_REMOVABLE_KEYS.forEach(
        (key) => localStorage.removeItem(key),
      );

      // TODO: this code clears legacy cookies and can be removed in October 2023;
      document.cookie.split(';').forEach((cookie) => {
        const [name] = cookie.split('=');
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      });
    }

    apolloClient.clearStore();
    apolloClient.stop();

    logoutAuth0({ returnTo: window.location.origin });
  }, [logoutAuth0, apolloClient]);

  const authInfo = useMemo(() => new AuthInfo(user), [user]);

  const auth = {
    // we spread authInfo for easier/direct access to its helper methods
    ...authInfo,
    // we spread Auth0 helpers for convenience so all our components
    // that use `useAuth` also have access to `useAuth0` provided helpers
    ...auth0,
    // overriding auth0's logout to perform additional cleanups
    logout: handleLogout,
    getToken: async () => (auth0.isAuthenticated
      ? (await auth0.getIdTokenClaims())?.__raw
      : undefined),
  };

  console.log('auth0', auth0);
  console.log('auth', auth);

  return auth;
};
