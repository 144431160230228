/* eslint-disable camelcase */

import { User } from '@auth0/auth0-react';

import { LoginUserRole, CompanyType, CustomUser } from './typings';

/**
 * This class acts as the central source of thruth for accessing user related
 * auth information. It's helper methods are made accessible via `useAuth` hook.
 *
 * The `AuthInfo` class can also be used independently outside the context
 * of react or if `useAuth` is not available (e.g. see ApolloProvider).
 *
 * In the future it may also provide access to permissions.
 * @see https://www.notion.so/shippioinc/202308_Difference-Between-FO-FOfW-FOfF-d574dcd931094a498ead24e51d28881c
 */
export class AuthInfo {
  /**
   * Provided by Auth0 after login.
   * It's set to undefined if no session exists.
   */
  user?: User;

  constructor(user?: User) {
    this.user = user as CustomUser;
  }

  /**
   * @deprecated use one of the other helpers below
   */
  getLoginUserRole = () => this.user?.login_user_role

  isCustomerUser = () => (this.user?.login_user_role === LoginUserRole.Customer);
  isStaffUser = () => (this.user?.login_user_role === LoginUserRole.Staff);
  isWarehouseUser = () => (this.user?.login_user_role === LoginUserRole.Warehouse);
  isSubsidiaryUser = () => (this.user?.company_type === CompanyType.Subsidiary);
  isForwarderUser = () => (
    this.user?.company_type === CompanyType.Forwarder
    // TODO: remove this once we fully transitioned to company types
    || (this.user?.login_user_role === LoginUserRole.Forwarder && !this.isSubsidiaryUser())
  )
  /**
   * A partner user is any user that is neither shippio staff nor a customer.
   * This includes forwarders, warehouses, subsidiaries...
   */
  isPartnerUser = () => this.user && !(this.isCustomerUser() || this.isStaffUser());
}
